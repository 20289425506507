* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 62.5%; */
}

.MuiDateCalendar-root {
  background-color: rgba(63, 226, 210, 0.1);
}

/* .muirtl-1l4hb8c-MuiStack-root {
  padding-top: 0 !important;
} */

.muirtl-1qs309j-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #3fe2d2 !important;
}

/* .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline  {
  border: 1px solid #222222 !important;
} */
