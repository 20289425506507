.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 14px;
  background-color: #ffffff;
  padding: 60px;
  border: 1px solid #000000;

  .title {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 20px;
  }

  .decs {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
  }
}
