.BirthdayPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 344px;
  width: 528px;
  border-radius: 32px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avtar {
    position: absolute;
    top: -125px;
    z-index: 1;
  }
  .avtar2 {
    display: none;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    .navbar {
      display: flex;
      justify-content: flex-end;
      height: 69px;
      width: 100%;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      background: #6666ff;
      align-items: center;
      padding-left: 38px;

      .title {
        padding-left: 142px;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
      }

      .crossIcon {
        cursor: pointer;
      }
    }
    .information {
      margin-top: 34px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      width: fit-content;
      color: #222222;
    }

    .numberContainer {
      display: flex;
      align-items: baseline;

      .count {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #222222;
      }

      .sign {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        color: #222222;
        margin-right: 2px;
      }
    }

    .wishes {
      font-weight: 400;
      font-size: 14px;
      line-height: 131.18%;

      color: #222222;
      white-space: pre-wrap;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 40px;
    }

    .boxSvg {
      display: none;
    }
  }
}

.birthdayGift {
  .avtar {
    display: none;
  }
  .avtar2 {
    display: block;
    position: absolute;
    top: -70px;
    z-index: 1;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .information {
      margin-top: 34px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
    }
    .birthdayDetails {
      display: none;
    }
    .boxSvg {
      display: block;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
