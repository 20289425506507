.LeadWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .colorLine2 {
    width: 100%;
    border: 0.5px solid rgba(158, 166, 180, 0.5);
    margin-bottom: 56.25px;
    margin-top: 19px;
  }
  .visible {
    display: none;
  }
  .desc {
    width: 100%;
    display: flex;
    //   padding-right: 83px;
    align-items: center;
    margin-bottom: 50px;

    .leadFilterSection {
      display: flex;
      padding-left: 94.5px;

      .Leads {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #9ea6b4;
        margin-left: 10px;
        cursor: pointer;
      }

      .number {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #9ea6b4;
      }

      .activeLeads {
        font-weight: 600;
        color: #222222;
      }
      .activeNumber {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #121314;
      }
    }

    .homeFilter {
      display: flex;
    }

    .dateInfo {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        cursor: pointer;
      }

      .activeIcon {
        rotate: 180deg;
      }

      .date {
        padding-right: 7px;
        padding-left: 68px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
        color: #9ea6b4;
      }
    }

    .leadsCount {
      display: flex;
      align-items: center;
      .lead {
        padding-left: 6px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: right;
        color: #222222;
      }
      .colorBorder {
        height: 13px;
        border-right: 1px solid #121314;
      }
      .count {
        padding-right: 6px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
        padding-left: 94.5px;
      }
    }

    .main {
      position: relative;
      .filterSvg {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 30px;
        width: 30px;

        SVG {
          path {
            fill: #9ea6b4;
          }
        }
      }
      .dot {
        height: 10px;
        width: 10px;
        background-color: #3fe2d2;
        border-radius: 50%;
        position: absolute;
        left: 60%;
        top: 10%;
      }
    }
  }
  .normalPackage {
    // display: grid;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    // grid-template-columns: auto auto auto;
    // row-gap: 19px;
    .package {
      margin-bottom: 19px;
      margin-left: 35px;
    }
    @media (max-width: 1480px) {
      .package {
        margin-bottom: 19px;
        margin-left: 25px;
      }
    }
    @media (max-width: 1050px) {
      justify-content: space-between;
      .package {
        margin-bottom: 19px;
        margin-left: 5px;
      }
    }
  }

  //   @media (max-width: 1450px) {
  //     justify-content: unset;
  //     .package {
  //       margin-bottom: 19px;
  //       margin-left: 85px;
  //     }
  //   }
  //   @media (max-width: 1200px) {
  //     justify-content: space-between;
  //     .package {
  //       margin-bottom: 19px;
  //       margin-left: 25px;
  //     }
  //   }
  //   @media (max-width: 1050px) {
  //     justify-content: space-between;
  //     .package {
  //       margin-bottom: 19px;
  //       margin-left: 5px;
  //     }
  //   }
}
