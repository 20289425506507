.img {
  margin-bottom: 59px;
  text-align: center;
}

.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #6666ff;
  text-align: center;
  margin-bottom: 2px;
}

.description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  text-align: center;
  margin-bottom: 30px;
  white-space: pre-wrap;
}
