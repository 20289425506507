.OtpScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  SVG {
    // margin-top: 30px;
    height: 100px;
    width: 100px;
  }

  .otpContainer {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin-bottom: 10px;

    .otp_code {
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      color: #222222;
      width: 45px !important;
      border: 1px solid transparent;
      border-bottom: 1px solid #777777;
      outline: none;
      margin-top: 30px;
      caret-color: #3fe2d2;
      padding-bottom: 5px;

      &:focus {
        border-bottom: 1px solid #777777;
      }
    }

    .otp_success {
      color: #3fe2d2;
      border-bottom: 1px solid #3fe2d2;
    }

    .otp_failure {
      color: #ff3b79;
      border-bottom: 1px solid #ff3b79;
    }
  }

  .existingAcc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-top: 20px;

    .login {
      color: #6666ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .circle {
    width: 100px;
    height: 100px;
    border: 3px solid #7b8795;
    border-radius: 50%;
    margin-top: 5%;
  }

  .newOTP {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #1f2933;
    margin-top: 20px;
    margin-bottom: 15px;

    .sendmeNewotp {
      color: #cbd2d9;
      text-decoration: underline;
    }
  }
}

.base_timer {
  width: 100px;
  height: 100px;
  position: relative;
}

.base_timer__svg {
  transform: scaleX(-1);
}

.base_timer__circle {
  fill: none;
  stroke: none;
}

.base_timer__path_elapsed {
  stroke-width: 5px;
  stroke: #ff3b79;
}

.base_timer__path_remaining {
  stroke-width: 5px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base_timer__label {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #777777;
  z-index: 10;
}

.base_timer__endlabel {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #ff3b79;
  z-index: 10;
}
