.TradingPackage {
  height: 265.64px;
  width: 187.13px;
  border-top-left-radius: 33px;
  border-top-right-radius: 33px;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
  background: #ffffff;
  box-shadow: 0px 2.19491px 8.77965px rgba(158, 166, 180, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    height: 32.95px;
    width: 33.02px;
    margin-top: 20.97px;
    margin-bottom: 2px;
    img {
      border-radius: 50%;
      width: 100%;
      height: fit-content;
      object-fit: contain;
    }
  }
  .description {
    display: flex;
    height: fit-content;
    flex-direction: column;
    margin-bottom: 6px;
    align-items: center;
    .text1 {
      width: fit-content;
      font-size: 21px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000;
    }
    .text2 {
      width: fit-content;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: #383434;
    }
  }
  .subSection1 {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 14.243px;
      line-height: 20px;
      color: #222222;
      padding-right: 2px;
    }
    .number {
      font-size: 31px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: right;
      color: #222222;
    }
  }
  .colorLine {
    width: 174.62px;
    border-bottom: 0.572482px solid rgba($color: #9ea6b4, $alpha: 0.3);
  }
  .section1 {
    margin-top: 8.52px;

    display: flex;
    flex-direction: column;
    .text {
      font-size: 14px;
      font-weight: 300;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000;
      margin-bottom: 4.73px;
    }
    .icon2 {
      height: 23.79px;
      width: 72.51px;
      margin-bottom: 21.68px;
    }
  }
  .shareContainer {
    position: relative;
    cursor: pointer;
    .background {
      position: absolute;
      top: 0;
      left: 0;
    }
    .main {
      position: relative;
      z-index: 1;
      display: flex;
      height: 40.94px;
      width: 167.62px;
      align-items: center;
      justify-content: center;

      .text {
        padding-left: 7px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        color: #222222;
        width: fit-content;
      }
      .shareIcon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
