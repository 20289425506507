.NoMoney {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 100%;
  //     height: 382px;
  //   background: #ffffff;
  //   border-radius: 15px;
  //   padding-top: 12px;
  //   margin-bottom: 50px;
  //   filter: drop-shadow(0px 2px 8px rgba(158, 166, 180, 0.4));

  .upperLayer {
    width: 100%;
    height: 123px;
    background-color: #ffffff;
    filter: drop-shadow(0px 2px 8px rgba(158, 166, 180, 0.4));
    border-radius: 15px;
    padding-top: 12px;
    margin-bottom: 56px;
    position: relative;

    .icon {
      position: absolute;
      z-index: 1;
      top: -20px;
      left: 50%;
    }

    .dataLayer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 92%;
      margin: auto;

      .wallet {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }

      .number {
        display: flex;
        align-items: baseline;

        .value {
          font-weight: 600;
          font-size: 34px;
          line-height: 46px;
          color: #000000;
          width: 30px;
        }

        .sign {
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          color: #000000;
        }
      }

      .btn {
        width: 100px;
        background: #dadada;
      }
    }

    .secondLayer {
      padding-top: 10px;
      width: 91%;
      margin: auto;

      .progressBarContainer {
        display: flex;
        align-items: center;

        .progressBar {
          width: 95%;
          height: 7px;
          border: 1px solid #3fe2d2;
          border-radius: 6px;
          margin-left: 32px;
        }

        .number {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;

          color: #222222;
        }
      }

      .text {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #222222;
      }
    }
  }

  .lowerLayer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 497px;
    background-color: #ffffff;
    filter: drop-shadow(0px 2px 8px rgba(158, 166, 180, 0.4));
    border-radius: 15px;

    .noLeads {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      margin-top: 18px;
      white-space: pre-wrap;
      text-align: center;
      margin-bottom: 16px;
    }

    .btn1 {
      width: 160px;
      background: #3fe2d2;
      margin-bottom: 36px;
    }

    .imgs {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}
