.NormalPackage {
  height: 119px;
  width: 327px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(158, 166, 180, 0.25);
  .section1 {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 15px;

    .subSection1 {
      display: flex;
      align-items: baseline;
      margin-top: 34px;
      // padding-right: 107px;
      margin-bottom: 12px;
      .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: right;
        color: #121314;
        padding-right: 2px;
      }
      .number {
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: right;
        color: #121314;
      }
    }
    .subSection2 {
      display: flex;
      flex-direction: column;
      // align-items: center;

      .date {
        margin-top: 6px;
        margin-bottom: 3px;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0em;
        // text-align: right;
        // width: fit-content;
        color: #9ea6b4;
        margin-right: 55px;
      }
      .ContainerWithSvg {
        display: flex;
        // align-items: center;

        .icon {
          height: 45px;
          width: 45px;
          margin-bottom: 12px;
          img {
            border-radius: 50%;
            height: 45px;
            width: 45px;
            object-fit: cover;
          }
        }

        .description {
          display: flex;
          flex-direction: column;
          padding-right: 10px;
          .part1 {
            display: flex;
            align-items: center;
            .text1 {
              font-size: 14px;
              font-weight: 300;
              line-height: 19px;
              letter-spacing: 0em;
              text-align: center;
              color: #9ea6b4;
              margin-right: 8px;
            }
            .colorLine {
              height: 11px;
              border-left: 1px solid #000000;
            }

            .text2 {
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              letter-spacing: 0em;
              text-align: right;
              color: #000000;
              margin-left: 8px;
            }
          }
          .text3 {
            margin-top: 3px;
            font-size: 14px;
            font-weight: 300;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: right;
            color: #383434;
          }
        }
      }
    }
  }
  .section2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 16px;
    height: 37px;
    width: 327px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background: rgba(158, 166, 180, 0.06);

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 200px;

      .countText {
        font-size: 12px;
        line-height: 16px;
        color: #7c7c7c;
        margin-right: 4px;
      }
    }

    .text {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: right;
      color: #000000;
      padding-left: 12px;
    }
    .icon1 {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
    }
    .share {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding-right: 88px;
      padding-left: 15px;
    }
  }
}
