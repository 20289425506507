.ProfileLinkPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 450px;
  width: 614px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fafafa;

  .navbar {
    width: 614px;
    height: 69px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #6666ff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    .title {
      width: fit-content;
      margin: 0 auto;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
  }
  .leadDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 25px;

    .Link {
      display: none;
    }
  }
  .colorLines {
    display: flex;
    height: 11px;
    width: fit-content;
    margin-bottom: 25px;

    .line1 {
      width: 184.3px;
      border-bottom: 0.5px solid #9ea6b4;
    }
    .text {
      height: 11px;
      width: 63.97px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #9ea6b4;
    }
  }
  .section2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #222222;
      margin-bottom: 20px;
    }
    .socialIcons {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      cursor: pointer;

      .icon1 {
        margin-right: 18.66px;
        width: 24px;
        height: 24px;
        img {
          width: 24px !important;
          height: 24px;
          object-fit: contain;
        }
      }
    }
    .information {
      // margin-bottom: 30px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000;
      height: 32px;
      width: 428px;
    }
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
}

.Linkcopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 410px;
  width: 614px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fafafa;

  .leadDescription {
    .description {
      .part1 {
        display: flex;
        align-items: center;
        .text1 {
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: right;
          color: #9ea6b4;
          margin-right: 8px;
        }

        .text2 {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: right;
          color: #000000;
        }
      }
      .text3 {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
        color: #121314;
      }
    }
    .information {
      margin-left: auto;
      margin-right: auto;
      margin-top: 18px;
      margin-bottom: 0;
      height: 49px;
      width: 462px;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #121314;
    }
    .btn2 {
      display: none;
    }
    .Link {
      display: block;
      cursor: pointer;

      .box {
        display: flex;
        height: 50px;
        width: 428px;
        border: 1px solid #222222;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 10px;
        .emailAdd {
          width: 90%;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          color: #000000;
          padding-left: 15px;
          word-wrap: break-word;
        }

        .copyIcon {
          padding-right: 12px;
          padding-left: 10px;
        }
      }
      .err {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        .error {
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0em;
          text-align: right;
          color: #3fe2d2;
          padding-right: 2px;
        }
      }
    }
  }

  .section2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;

    .text {
      font-weight: 700;
    }

    .information {
      font-weight: 700;
    }
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 25px;
  }
}
