.BirthdayPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 520px;
  width: 528px;
  border-radius: 32px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  // transform: scale(0.8);

  .avtar {
    position: absolute;
    top: -140px;
    z-index: 1;
    transform: scale(0.5);
  }

  .information {
    margin-top: 70px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: #222222;
  }

  .wishes {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 25px;
  }

  .items {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-bottom: 7px;
  }

  .icons {
    // display: flex;
    // flex-direction: column;

    .layer1 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      .img1 {
        img {
          width: 55px;
          height: 30px;
        }
      }
      .img2 {
        img {
          width: 48px;
          height: 33px;
        }
      }
      .img3 {
        img {
          width: 24px;
          height: 55px;
        }
      }

      .img4 {
        img {
          width: 45px;
          height: 44px;
        }
      }
    }

    .line {
      width: 330px;
      background-color: rgba(158, 166, 180, 0.2);
      height: 1px;
      margin-bottom: 15px;
    }

    .layer2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      .img5 {
        img {
          width: 49px;
          height: 39px;
        }
      }

      // .img6 {
      //   img {
      //     width: 49px;
      //     height: 39px;
      //   }
      // }

      // .img7 {
      //   img {
      //     width: 49px;
      //     height: 39px;
      //   }
      // }
    }
  }
}
