.ResetPasswordSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .resetPasssword {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
