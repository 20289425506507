.SideDrawer {
  width: 205px;
  padding-right: 30px;
  height: 100%;
  border-left: 1px solid #eeeeee;

  .logo {
    padding-top: 26px;
    margin-bottom: 59px;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 23px;
      width: 116px;
      object-fit: contain;
    }
  }
  .link {
    all: unset;
  }
}
