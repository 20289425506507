.container {
  background-color: white;
  filter: drop-shadow(0px 2px 8px rgba(158, 166, 180, 0.4));
  border-radius: 15px;
  padding: 20px 40px;

  .firstDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .icon {
      position: absolute;
      z-index: 1;
      top: -40px;
      left: 50%;
    }

    .wallet {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }

    .number {
      display: flex;
      align-items: baseline;

      .value {
        font-weight: 600;
        font-size: 34px;
        line-height: 46px;
        color: #000000;
      }

      .sign {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
      }
    }

    .btn {
      width: 100px;
    }
  }

  .secondLayer {
    padding-top: 10px;
    width: 100%;
    margin: auto;

    .progressBarContainer {
      display: flex;
      align-items: center;
      width: 100%;

      .progressBar {
        flex: 1;
        height: 7px;
        border: 1px solid #3fe2d2;
        border-radius: 6px;
        margin-left: 12px;

        .progress {
          height: 100%;
          background-color: #3fe2d2;
          border-radius: 6px;
        }
      }

      .number {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #222222;
      }
    }

    .text {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #222222;
    }
  }
}
