.Leads {
  width: 92%;
  margin: auto;

  .firstDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: white;
    filter: drop-shadow(0px 2px 8px rgba(158, 166, 180, 0.4));
    border-radius: 15px;
    padding: 0 40px;
    margin-bottom: 35px;
    position: relative;

    .icon {
      position: absolute;
      z-index: 1;
      top: -20px;
      left: 50%;
    }

    .wallet {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }

    .number {
      display: flex;
      align-items: baseline;

      .value {
        font-weight: 600;
        font-size: 34px;
        line-height: 46px;
        color: #000000;
      }

      .sign {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
      }
    }

    .btn {
      width: 100px;
    }
  }

  .graphContainer {
    height: 372px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(158, 166, 180, 0.4);
    border-radius: 22.5px;
    padding-right: 23px;
    padding-top: 21px;
    margin-bottom: 49px;

    .dataLayer {
      display: flex;
      margin-bottom: 18px;

      .registered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 227.75px;
        height: 72px;
        background: #f0f2f4;
        border-radius: 225px;
        margin-left: 15px;

        .registeredNumber {
          font-weight: 600;
          font-size: 30px;
          line-height: 41px;
          color: #000000;
        }

        .text {
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #000000;
        }
      }

      .earnings {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 227.75px;
        height: 72px;
        border-radius: 15px;

        .earningsNumberConatiner {
          display: flex;
          align-items: baseline;

          .earningsNumber {
            font-weight: 400;
            font-size: 30px;
            line-height: 41px;
            color: #000000;
            margin-left: 5px;
          }

          .sign {
            font-weight: 400;
            font-size: 20px;
            line-height: 41px;
            color: #000000;
          }

          .earningsText {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
          }
        }
      }
    }

    .filterLayer {
      display: flex;
      justify-content: space-between;
      width: 480px;
      // width: 54.5%;
      margin-bottom: 18px;

      .filterTypes {
        display: flex;
        justify-content: space-between;
        width: 220px;
        // width: 43%;
        // background-color: red;

        .filterText {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #000000;
          cursor: pointer;
          text-transform: uppercase;
          border-radius: 31.5px;
          padding: 5px 15px;

          &:hover {
            background: #f0f2f4;
          }
        }

        .activeFilterText {
          background: #f0f2f4;
          font-weight: 600;
        }
      }

      .labeslContainer {
        display: flex;
        width: 160px;
        // width: 40%;
        justify-content: space-between;
        // background-color: yellow;

        .label {
          display: flex;
          align-items: center;
          justify-content: center;

          .graphText {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #7c7c7c;
            margin-left: 7px;
          }

          .dottedline {
            // width: 18px;
            color: #9f89ff;
            // height: 3px;
            font-weight: 900;
          }

          .line {
            width: 15px;
            background-color: #2acae6;
            height: 3px;
          }
        }
      }
    }
  }

  .border {
    height: 1px;
    background-color: rgba(158, 166, 180, 0.5);
    margin-bottom: 20px;
  }
}
