.ErrorPopup {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 100;

  .container {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .icon {
      height: 45px;
      width: 45px;
      margin-bottom: 12px;
      margin: 0 auto;
      img {
        border-radius: 50%;
        height: 45px;
        width: 45px;
        object-fit: cover;
      }
    }
    .text {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      color: #000000;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .button {
      width: 280px;
      margin: 0 auto;
    }

    .button2 {
      width: 280px;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}

.nonDispaly {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}
