.NormalButton {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  border-radius: 18px;
  padding: 6px 27px 6px 27px;
  border: 1px solid #9ea6b4;
  border-radius: 18px;
  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #9ea6b4;
  }
}
.active {
  height: fit-content;
  width: fit-content;
  background-color: #3fe2d2;

  .text {
    color: #222222;
  }
}
