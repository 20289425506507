.container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  .modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PopupType4 {
    height: 300px;
    width: 500px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    gap: 10px;
    border-radius: 8px;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .logo {
        margin-bottom: 20px;
        width: 140px;
        height: 32px;

        img {
          width: 100%;

          height: fit-content;
          object-fit: contain;
        }
      }
      .desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        .text1 {
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
        }
        .text2 {
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}
