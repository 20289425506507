.PrimaryLayout {
  width: 100vw;
  height: 100vh;
  display: flex;
  .fixedPart {
    height: 100%;
  }

  .leftside {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    color: black;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fafafa;

    .header {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // padding-left: 100px;
      padding-left: 8%;
      margin-bottom: 43px;

      .text {
        padding-right: 10px;

        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: -0.035em;
          text-align: right;
          color: #222222;
        }

        .logout {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          text-decoration-line: underline;
          color: #6666ff;
          cursor: pointer;
        }
      }
    }

    .children {
      // display: flex;
      // flex-direction: column;
      flex: 1;
    }
  }
}
