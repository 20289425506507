.Home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .section1 {
    padding-left: 8%;
    padding-right: 6.7%;
    width: 100%;
    display: flex;
    align-items: center;
    // padding-right: 80px;
    margin-bottom: 63.85px;
    .date {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      color: #121314;
      padding-right: 10px;
    }
    .colorLine {
      border: 1px solid #121314;
      height: 13px;
    }
    .text {
      padding-left: 7px;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: right;
      color: #222222;
    }
  }
  .section2 {
    width: 90%;
    .slideWrap {
      padding-top: 20px;
      padding-bottom: 30px;
      width: 16.67%;
      display: flex !important;
      align-items: center;
      justify-content: center;

      @media (max-width: 1600px) {
        width: 20%;
      }

      @media (max-width: 1450px) {
        width: 25%;
      }

      @media (max-width: 1150px) {
        width: 33.33%;
      }

      @media (max-width: 880px) {
        width: 50%;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .section3 {
    padding-left: 8%;
    padding-right: 6.4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .normalPackage {
      width: 100%;
      display: flex;
    }
  }
}
