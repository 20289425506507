.PersonalInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .input {
    margin-bottom: 70px !important;
  }

  .date {
    background-color: red;
  }

  .btn {
    margin-top: 70px;
  }

  .error {
    color: #ff3b79;
    font-size: 12px;
    text-align: right;
    width: 415px;
  }
}
