.PopupType3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  width: 414px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fafafa;
  // cursor: pointer;

  .navbar {
    width: 414px;
    height: 69px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    background-color: #6666ff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;

    .crossIcon {
      cursor: pointer;
    }

    .title {
      flex: 1;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
  }
  .leadDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 50px;
    .imageContainer {
      margin-top: 19px;
      border-radius: 50%;
      height: 33px;
      width: 33px;
      svg {
        border-radius: 50%;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      .part1 {
        .text1 {
          font-size: 14px;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: right;
          color: #9ea6b4;
          margin-right: 8px;
        }
        .colorLine {
          height: 11px;
          border-left: 0.5px solid #9ea6b4;
          margin-right: 8px;
        }
        .text2 {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: right;
          color: #000000;
        }
      }
      .text3 {
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: right;
        color: #121314;
      }
    }
    .information {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      // margin-bottom: 5px;
      height: 49px;
      width: 462px;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #121314;
      span {
        font-weight: 700;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    .button {
      width: 100px;
    }
  }
}
