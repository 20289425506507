.SignIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .input {
    margin-bottom: 30px !important;
  }

  .generalError {
    margin: 0;
    color: #ff3b79;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .forget {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    text-decoration-line: underline;

    color: #6666ff;
    margin-bottom: 47px;
    cursor: pointer;
  }

  .existingAcc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-top: 30px;

    .login {
      color: #6666ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
