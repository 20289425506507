.Profile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .iconContinaer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    img {
      position: absolute;
      // height: 220px;
      // width: 180px;
      // object-fit: contain;
    }
  }

  .userInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 316px;
    height: 88px;
    background-color: #ffffff;
    border-radius: 20px;
    margin-bottom: 30px;

    .name {
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      color: #6666ff;
      margin-bottom: 3px;
    }

    .Container {
      display: flex;
      align-items: center;

      .text {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-left: 6px;
      }

      .line {
        height: 10px;
        width: 2px;
        color: red;
        margin-right: 6px;
        margin-left: 6px;
      }

      .levelNumber {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #9ea6b4;
      }
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      color: #121314;
    }
  }
  .levelsContainer {
    display: flex;
    margin-bottom: 40px;

    .img1 {
      img {
        width: 55px;
        height: 30px;
      }
    }
    .img2 {
      img {
        width: 48px;
        height: 33px;
      }
    }
    .img3 {
      img {
        width: 24px;
        height: 55px;
      }
    }

    .img4 {
      img {
        width: 45px;
        height: 44px;
      }
    }

    .img5 {
      img {
        width: 49px;
        height: 39px;
      }
    }

    .img6 {
      img {
        width: 61px;
        height: 18px;
      }
    }
    .img7 {
      img {
        width: 54px;
        height: 20px;
      }
    }
  }

  .personalInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 590px;
    // max-width: 590px;
    // min-width: 590px;
    margin-bottom: 20px;
    cursor: pointer;

    .info {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #222222;
    }
  }

  .line {
    width: 590px;
    height: 1px;
    background-color: #9ea6b4;
    margin-bottom: 25px;
  }

  .bankAccount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 590px;
    margin-bottom: 25px;

    .payment {
      color: var(--black, #222);
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }

    .addAcc {
      color: var(--main-second, #66f);
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .addAcc2 {
    color: var(--main-second, #66f);
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    margin-bottom: 25px;
  }

  .bankAccountInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 590px;
    height: 40px;
    padding: 0 20px;
    border-radius: 10px;
    background-color: var(--grey-2, #f0f2f4);
    margin-bottom: 20px;
  }

  .shareApp {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 15px;
  }

  .btn {
    display: flex;

    .btntext {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      color: #222222;
      margin-right: 10px;
    }
  }
}
