.Levels {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;

  .level {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background: #ffffff;
    border-radius: 14px;
    position: relative;

    .lock {
      position: absolute;
      top: 60%;
      right: 5px;
    }
  }
}
