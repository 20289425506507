.Menu {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 2;
  width: 428px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 2.19491px 8.77965px rgba(158, 166, 180, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  overflow-y: scroll;
  max-height: 200px;

  .description {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 14px;

    .value1 {
      width: fit-content;
      padding-left: 14px;
      text-align: right;
      color: #222222;
      margin-bottom: 12px;
      margin-top: 12px;

      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: right;
    }
    .value2 {
      width: fit-content;
      padding-right: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: right;
      color: #222222;
      margin-bottom: 12px;
      margin-top: 12px;
    }
    .colorLine {
      border-left: 1px solid #222222;
      height: 15px;
    }
  }

  .ColorBorder {
    width: 401px;
    border-bottom: 1px solid rgba($color: #9ea6b4, $alpha: 0.3);
    padding-right: 12px;
    padding-left: 11px;
  }
}
