.container {
  display: flex;
  flex-direction: column;
  height: fit-content;

  .input {
    width: 428px;
    // height: 42px;
    // margin-bottom: 30px;
    // background-color: red;

    .icon {
      cursor: pointer;
      margin-left: 10px;
    }

    &:active {
      border-color: #000;
    }
  }

  .label {
    font-size: 14px !important;
  }

  .new {
    background-color: red;
  }

  .focused {
    border-color: #000;
    // background-color: rgba($color: #fff, $alpha: 0.8);
    // opacity: 0.5;
  }
}
