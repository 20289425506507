.TCPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .description {
    margin: 0 auto;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #222222;
    // width: 40%;
    height: 200px;
    overflow-y: scroll;
    white-space: pre-wrap;
    margin-top: 4px;
    margin-bottom: 31px;
    padding-right: 20px;

    width: 600px;

    h3,
    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      // underline
      text-decoration: underline;
      text-align: center;

      margin-bottom: 20px;
    }

    h4 {
      // no underline
      text-decoration: none;
    }

    p {
      width: 100%;
      text-align: right;
      margin-bottom: 20px;
    }
  }

  .CheckboxContainer {
    display: flex;
    align-items: center;
    width: 270px;

    .des1 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
    }

    .selected {
      color: #3fe2d2;
    }
  }

  .btn {
    width: 226px;
    margin-top: 31px;
  }
}
