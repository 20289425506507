.EmailVerification {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .input {
    margin-bottom: 70px !important;
  }
}
