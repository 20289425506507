.PasswordChecker {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 6px;

  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #777777;
    padding-right: 8px;
    width: fit-content;
  }

  .errorText {
    color: #ff3b79;
  }
}
