.ImageWrap {
  width: 80%;
  display: flex;
  position: relative;
  align-items: center;
  transform: translateX(-100%);

  .icon1 {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    // transform: translateX(40%);
    // transform: translate(-165%);
    margin-right: -37px;
    // z-index: 5;
  }
  .container {
    position: absolute;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: black;
    margin-right: -72px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    right: 0;
    .number {
      position: absolute;
      top: 8px;
      margin-left: auto;
      margin-right: auto;
      right: 8px;
      font-size: 8px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0em;
      text-align: right;
      color: #ffffff;
    }
  }

  // .icon2 :nth-child(2) {
  //   margin-right: 0;
  // }
  // .icon3 {
  //   height: 25px;
  //   width: 25px;
  //   border-radius: 50%;
  //   transform: translate(100%);
  //   z-index: 3;
  // }
  // .icon4 {
  //   height: 25px;
  //   width: 25px;
  //   border-radius: 50%;
  //   transform: translateX(150%);
  //   z-index: 2;
  // }
  // .icon5 {
  //   height: 25px;
  //   width: 25px;
  //   border-radius: 50%;
  //   transform: translate(200%);
  //   z-index: 1;
  // }
}
