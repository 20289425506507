.Navlist {
  height: 40px;
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;

  .icon {
    padding-left: 12px;
    padding-right: 12px;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    height: fit-content;
    color: #777777;
  }
}
.activeState {
  background: #3fe2d2;
  SVG {
    path {
      fill: #222222;
    }
  }
  .title {
    color: #222222;
  }
}
