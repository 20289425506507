.SignUpSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .video {
    height: 310px;
    width: 550px;
    margin-bottom: 50px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .CheckboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .des1 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
    }

    .selected {
      color: #3fe2d2;
    }
  }

  .errorText {
    color: #ff3b79;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .btn {
    width: 226px;
  }
}
