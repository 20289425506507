.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}

.PaymentTransfer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .img {
    margin-bottom: 24px;
  }

  .paymentMethod {
    color: var(--black, #222);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
  }

  .dropdownContainer {
    position: relative;
    cursor: pointer;

    .dropdownMenu {
      position: absolute;
      top: 70%;
      right: 0;
      width: 428px;
      height: 200px;
      border-radius: 6px;
      border: 1px solid rgba(196, 196, 196, 0.5);
      background-color: #ffffff;
      overflow: scroll;
      z-index: 2;
      padding-top: 8px;

      &::-webkit-scrollbar {
        display: none;
      }

      .dropdownItem {
        display: flex;
        align-items: center;
        height: 40px;
        padding-right: 16px;
        margin-bottom: 4px;

        &:hover {
          background-color: #f2f2f2;
        }
      }
    }

    .input {
      cursor: pointer;
      SVG {
        rotate: 180deg;
      }
    }

    .active {
      background-color: #f2f2f2;
    }
  }

  .apiError {
    font-size: 12px;
    line-height: 14px;
    color: #ff3b79;
    margin-top: 20px;
    text-align: center;
  }

  .btn {
    margin-top: 40px;
  }

  .btn2 {
    margin-top: 20px;
    background-color: #dadada;
    color: #000;
  }
}
