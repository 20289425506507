.username {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .checking {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 428px;
  }
}
