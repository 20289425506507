.signup {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .checking {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 428px;
  }

  .input {
    margin-bottom: 25px !important;
  }

  .existingAcc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-top: 30px;

    .login {
      color: #6666ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
