.FilterPopup {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
  height: 527px;
  width: 528px;
  border-radius: 32px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  scale: 0.9;

  .navbar {
    display: flex;
    justify-content: flex-end;
    height: 69px;
    width: 528px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    background: #6666ff;
    align-items: center;
    padding-left: 38px;
    .title {
      padding-left: 142px;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
    .crossIcon {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .section1 {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 34px;
      margin-bottom: 30px;
      .text1 {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea6b4;
        padding-bottom: 2px;
        border-bottom: 0.5px solid #9ea6b4;
      }
      .filter {
        display: flex;
        padding-right: 187px;
        align-items: center;
        padding-left: 132px;
        .text2 {
          font-size: 22px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: center;
          color: #000000;
        }
      }
    }
    .search {
      display: flex;
      position: relative;
      // cursor: pointer;

      .searchbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        width: 428px;
        border-radius: 30px;
        border: 1px solid #9ea6b4;
        margin-bottom: 18px;
      }
      input::placeholder {
        padding-right: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: right;
        color: #7c7c7c;
      }
      input {
        all: unset;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: right;
        color: #222222;
        padding-right: 15px;
        // cursor: pointer;
      }
      input:focus {
        border: 1.5px solid #222222;
      }

      .searchIcon {
        position: absolute;
        top: 14px;
        left: 0;
        padding-left: 19px;
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .section2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    .title {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: right;
      color: #000000;
      margin-bottom: 43px;
    }
    .messege {
      position: absolute;
      top: 271px;
      left: 50%;
      transform: translate(-50%, -50%);
      SVG {
        path {
          width: 200px;
        }
      }
    }
    .value {
      top: 13px;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      display: flex;
      align-items: center;

      .text,
      .number {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #222222;
      }
      .dash {
        font-size: 20px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #222222;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 2px;
      }
      .minValue {
        display: flex;
      }
      .maxValue {
        display: flex;
      }
    }
    .rangebar {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      .number1 {
        height: 24px;
        width: 22px;
        color: #9ea6b4;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        padding-top: 6px;
      }
      .number2 {
        height: 20px;
        width: 36px;
        color: #9ea6b4;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        padding-left: 2px;
        padding-top: 2px;
      }
    }
  }
  .section3 {
    // margin-top: 40px;
    margin-top: 25px;
    margin-bottom: 30px;
    // margin-bottom: 35px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 100%;
      margin-bottom: 18px;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: right;
      color: #000000;
    }
    .btn_box1 {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;
      cursor: pointer;
    }
    .btn_box2 {
      width: 50%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      cursor: pointer;
    }
    .colorBorder {
      width: 428px;
      border-bottom: 1px solid rgba($color: #9ea6b4, $alpha: 0.3);
    }
  }
  .section4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    .icons {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .text {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
      }
    }
    .box {
      display: flex;
      flex-direction: row-reverse;
      height: 36px;
      width: 324px;
      border-radius: 20px;
      border: 1px solid #9ea6b4;
      // justify-content: space-between;
      align-items: center;
      overflow: hidden;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
        color: #9ea6b4;
      }
      .box1 {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #9ea6b4;
      }

      .box4 {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #9ea6b4;
      }
      .active {
        background: #3fe2d2;
        .text {
          color: #222222;
        }
      }
    }
  }
}
