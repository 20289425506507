* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
}

.FilterSliderange {
  border: none;
  box-shadow: none;
  padding: 0 10px;
  width: 100%;
}
.FilterSliderange .thumb::before {
  border: 1px solid #3fe2d2;
  box-shadow: none;
}
/* .FilterSliderange .thumb .thumb-left .caption .max-caption {
  display: none !important; */
/* } */
.FilterSliderange .bar-inner {
  display: flex;
  margin-top: 2px;
  border: none;
  box-shadow: none;
  height: 1px;
  /* border: 1px solid #3FE2D2; */
}
.FilterSliderange .bar-right {
  border: none;
  box-shadow: none;
  margin-top: 2px;
  height: 1px;
  background-color: #3fe2d2;
}
.FilterSliderange .bar-left {
  border: none;
  box-shadow: none;
  margin-top: 2px;
  height: 1px !important;
  background-color: #3fe2d2;
  padding: 0;
  border-radius: 0;
}
.FilterSliderange .thumb .caption * {
  display: none;
}
