.ForgetPasssword {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .Input {
    margin-bottom: 40px !important;
  }

  .generalError {
    margin: 0;
    color: #ff3b79;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .enteremail {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-top: 30px;
  }

  .existingAcc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-top: 10px;
    text-align: center;

    .login {
      color: #6666ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
