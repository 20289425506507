.BirthdayPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 410px;
  width: 528px;
  border-radius: 32px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avtar {
    position: absolute;
    top: -185px;
    z-index: 1;
  }
  .avtar2 {
    display: none;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .navbar {
      display: flex;
      justify-content: flex-end;
      height: 69px;
      width: 528px;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      background: #6666ff;
      align-items: center;
      padding-left: 38px;
      .title {
        padding-left: 142px;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
      }
      .crossIcon {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
    .information {
      margin-top: 34px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      height: 40px;
      width: fit-content;
      color: #222222;
      white-space: pre-wrap;
    }
    .birthdayDetails {
      display: flex;
      flex-direction: column;
      margin-top: 44px;
      margin-bottom: 77px;
      .count {
        font-size: 32px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: #222222;
      }
      .wishes {
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #222222;
      }
    }
    .boxSvg {
      display: none;
    }
  }
}

.birthdayGift {
  .avtar {
    display: none;
  }
  .avtar2 {
    display: block;
    position: absolute;
    top: -70px;
    z-index: 1;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .information {
      margin-top: 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
    }
    .birthdayDetails {
      display: none;
    }
    .boxSvg {
      display: block;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}
