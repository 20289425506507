.UpdatePersonalInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .img {
    margin-bottom: 24px;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 430px;
    margin-bottom: 24px;

    .header {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #222222;
    }
  }

  .logoutDeleteContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 428px;
  }
  .logout {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #6666ff;
    margin-bottom: 20px;
    // width: 425px;
    cursor: pointer;
  }

  .delete {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #ff3b79;
    margin-bottom: 20px;
    // width: 425px;
    cursor: pointer;
  }

  .apiError {
    margin: 0;
    color: #ff3b79;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .btn2 {
    margin-top: 20px;
    background-color: #dadada;
    color: #000;
  }
}
