.button {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 428px;
  height: 39px;
  min-height: 39px;
  border-radius: 154px;
  cursor: pointer;
  background-color: #3fe2d2;
  color: #222222;
  border: none;

  // .prefixIcon {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-right: 10px;
  // }

  // .suffixIcon {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-left: 10px;
  // }

  &:disabled {
    cursor: no-drop;
    background-color: #dadada;
    color: #999999;
  }
}

// .primaryButtonWrapper {
//   // background-color: $Cyan4;
//   border: none;
//   // color: $Cyan5;
//   // background-color: $Cyan4;

//   &:hover:enabled {
//     // background-color: $Cyan7;

//     .button {
//       // background-color: $Cyan7;
//     }
//   }
// }

// .secondaryButtonWrapper {
//   // background-color: $White;
//   // border: 1px solid $Gray5;
//   // color: $Gray1;

//   &:hover:enabled {
//     // background-color: $Cyan5;
//     border: none;

//     .button {
//       // background-color: $Cyan5;
//       border: none;
//     }
//   }
// }
